import React, { useEffect } from "react";
import s from "./ArticlesBoard.scss";
import { useState } from "react";
import { Image } from "components/contentful/image/Image";
import { calcArticleReadingTime } from "utils/readingTime";
import { DateFormatted } from "components/date-formatted/DateFormatted";
import ArrowSmall from "assets/svg/arrow-small.svg";
import { Container } from "components/container/Container";
import { Link } from "components/link/Link";
import { Button } from "../../button/Button";

const Item = ({ item }: any) => {
  return (
    <a href={item.url} target="_blank" className={s("item")}>
      {item.featuredImage && (
        <div className={s("imageContainer")}>
          <img className={s("image")} src={item.featuredImage} />
        </div>
      )}
      {item.publishDate && (
        <div className={s("itemDate")}>
          <DateFormatted date={item.publishDate} />
        </div>
      )}
      {item.htmlTitle && (
        <div className={s("itemTitle")}>
          {String(item.htmlTitle).length < 100
            ? item.htmlTitle
            : String(item.title).slice(0, 150) + "..."}
        </div>
      )}
      <span className={s("readMore")}>Read More</span>
    </a>
  );
};

export const ArticlesBoard = ({
  list,
  headerItem,
  item,
  extraSpacingBottom
}: any) => {
  const { items } = list;
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 3;
  const { title, cta, text } = item;

  return (
    <Container>
      <div className={s("container", { extraSpacingBottom })}>
        <div className={s("header")}>
          {title && <h4 className={s("title")}>{title}</h4>}
          {cta && (
            <Link to={cta.href} className={s("loadMore")}>
              {cta.label} {<ArrowSmall className={s("arrow")} />}
            </Link>
          )}
        </div>
        <div className={s("board")}>
          {items.map((item: any, i: number) => {
            return <Item key={i} item={item} />;
          })}
        </div>
        {item.cta && (
          <div className={s("cta")}>
            <Button
              label={item.cta.label}
              href={item.cta.file ? item.cta.file.file.url : item.cta.href}
            />
          </div>
        )}
      </div>
    </Container>
  );
};
