import React from 'react';

interface IProps {
  date: string;
  noYear: boolean;
}


export const DateFormatted = ({ date, noYear=false }: IProps) => {
  
  const currentYear = new Date().getFullYear();
  const dateSplit = date.split('-')
  const dayNum = parseInt(dateSplit[2])
  const monthNum = parseInt(dateSplit[1])
  const yearNum = (parseInt(dateSplit[0]) !== currentYear) ? ', ' + parseInt(dateSplit[0]) : ''
  const months = ['', 'Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.']
  if(noYear) {
    return (
      <>{months[monthNum]} {dayNum}</>
    );
  } else {
    return (
      <>{months[monthNum]} {dayNum}{yearNum}</>
    );
  }
 
};
